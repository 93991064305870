<template>
  <LayoutContainer
    class="relative flex h-full select-none flex-col bg-zipup-gray-4"
  >
    <div class="relative grow">
      <div class="absolute inset-0">
        <NuxtPage />
      </div>
    </div>

    <ZipupNavbar
      class="z-30 flex flex-none items-end"
      :safe="true"
    ></ZipupNavbar>
  </LayoutContainer>
</template>
